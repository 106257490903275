import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom'

import ReactGA from 'react-ga'

import {
    REACT_GA_ENABLED
} from '../../gener8tiveConstants'

import { withStyles } from '@material-ui/core/styles'

// import Home from '../home'
// import About from '../about'
// import KComposition from '../kcomposition'

import AppHeader from './appHeader';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'
import Grid from "@material-ui/core/Grid"

import Loadable from 'react-loadable';

import {
    initApp,
    // initWeb3
} from '../../modules/app'

const Loading = () => <CircularProgress />

// const Mutations = Loadable({
//     loader: () => import('../mutations'),
//     loading: Loading
// })

const Tones = Loadable({
    loader: () => import('../tones'),
    loading: Loading
})

const Tone = Loadable({
    loader: () => import('../tones/tone.js'),
    loading: Loading
})

const KCompositions = Loadable({
    loader: () => import('../kcompositions'),
    loading: Loading
})

const KComposition = Loadable({
    loader: () => import('../kcomposition'),
    loading: Loading
})

const Mutations = Loadable({
    loader: () => import('../mutations'),
    loading: Loading
})

const Variant = Loadable({
    loader: () => import('../variant'),
    loading: Loading
})

const OneOffs = Loadable({
    loader: () => import('../oneoffs'),
    loading: Loading
})

const About = Loadable({
    loader: () => import('../about'),
    loading: Loading
})

const Metro = Loadable({
    loader: () => import('../metro'),
    loading: Loading
})

var appUtils = require('../../utils/app')

const styles = theme => ({
    metroTab: {
        width: 95,
        minWidth: 95
    },
    tonesTab: {
        width: 90,
        minWidth: 90
    },
    kCompsTab: {
        // width: 170,
        // minWidth: 170
    },
    mutationsTab: {
        width: 120,
        minWidth: 120
    },
    oneOffsTab: {
        width: 120,
        minWidth: 120
    },
    infoTab: {
        width: 90,
        minWidth: 90
    },
    introContainer: {
        paddingTop: '70px',
        paddingLeft: '2em',
        paddingRight: '2em',
        paddingBottom: '70px'
    },
    introParagraph: {
        paddingTop: '1em'
    }
})

class App extends Component {
    state = {
        activePage: '/',
        menuOpen: false
    }

    constructor(props) {
        super(props)
        
        this.props.initApp()
        // this.props.initApi()
        // this.props.initWeb3()

        if(REACT_GA_ENABLED) {
            ReactGA.initialize('UA-77905841-4')
        }

        if(this.props.pathname === '/') {
            this.state = { activePage: '/' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('metro')
            }
        }
        else if(this.props.pathname === '/metro') {
            this.state = { activePage: '/' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('metro')
            }
        }
        else if(this.props.pathname.includes('tones')) { //=== '/tones') {
            this.state = { activePage: 'tones' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('tones')
            }
        }
        else if(this.props.pathname.includes('mutations') || this.props.pathname.includes('variant')) { //=== '/mutations') {
            this.state = { activePage: 'mutations' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('mutations')
            }
        }
        else if(this.props.pathname === '/kcompositions' || this.props.pathname.includes('kcomposition')) {
            this.state = { activePage: 'kcompositions' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('kcompositions')
            }
        }
        else if(this.props.pathname === '/about') {
            this.state = { activePage: 'about' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('about')
            }
        }
        else if(this.props.pathname === '/oneoffs') {
            this.state = { activePage: 'oneoffs' }
            if(REACT_GA_ENABLED) {
                ReactGA.pageview('oneoffs')
            }
        }
    }

    handleMetroLinkToAbout = () => {
        this.setActivePage('about')
    }

    handleActivePageChange = (event, value) => {
        this.setActivePage(value)
    }

    setActivePage = (page) => {
        this.setState({ activePage: page })
    }

    renderInitError = () => {
        const { classes } = this.props
        if(this.props.app.error === 'network_mismatch') {
            return (
                <Grid container justify='center' className={classes.introContainer}>
                    <Grid item xs={9}>
                        <Typography variant="h3" gutterBottom align='left' className={classes.mainHeading}>Wrong Ethereum Network</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" gutterBottom align='justify' className={classes.introParagraph}>
                            <p>You appear to be connected to the<b> { appUtils.upperCaseFirstLetter(this.props.app.selectedNetwork) } </b>Network with <b>{appUtils.upperCaseFirstLetter(this.props.app.web3Provider)}</b>.
                                <br/>
                                This version of this DApp was built for the <b>{ appUtils.listNetworksInContractNetworks(this.props.app.dappNetworks) }</b> Network - please switch your network.
                            </p>
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container justify='center' className={classes.introContainer}>
                    <Grid item xs={9}>
                        <Typography variant="h3" gutterBottom align='left' className={classes.mainHeading}>We're sorry, errors happen ;(</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" gutterBottom align='justify' className={classes.introParagraph}>
                            Couldn't initialize dApp: { this.props.app.error }
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    }

    handleClose = () => {
        
    }

    handleClick = (event) => {
        this.setState({menuOpen: true})
      };

    render() {
        const { classes } = this.props

        if(this.props.app.error) {
            return (
                this.renderInitError()
            )
        }

        return (
            <React.Fragment>
                <Router>
                    <AppHeader
                        changeOnScroll
                        leftLinks={
                            <Tabs
                                value={this.state.activePage}
                                onChange={this.handleActivePageChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                centered
                            >
                                
                                {/* <Tab value='home' icon={<HomeIcon/>} component={Link} to="/" /> */}
                                <Tab className={classes.metroTab} value='/' label="Metro" component={Link} to="/" />
                                <Tab className={classes.kCompsTab} value='kcompositions' label="k-Compositions" component={Link} to="/kcompositions" />
                                <Tab className={classes.tonesTab} value='tones' label="Tones" component={Link} to="/tones" />
                                <Tab className={classes.mutationsTab} value='mutations' label="Mutations" component={Link} to="/mutations" />
                                <Tab className={classes.oneOffsTab} value='oneoffs' label="One-Offs" component={Link} to="/oneoffs" />
                                <Tab className={classes.infoTab} value='about' label="Info" component={Link} to="/about" />
                            </Tabs>
                        }/>
                    
                    {/* {
                        this.props.app.web3 ?  */}
                            <Route exact path='/'
                                render={ (props) =>
                                <Metro
                                    handleMetroLinkToAbout={ this.handleMetroLinkToAbout }
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}

                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path='/metro'
                                render={ (props) =>
                                <Metro
                                    handleMetroLinkToAbout={ this.handleMetroLinkToAbout }
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}

                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path='/tones'
                                render={ (props) =>
                                <Tones
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}

                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path={'/tones/:id'}
                                render={ (props) =>
                                <Tone
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}
                    
                    {/* {
                        this.props.app.web3 ?  */}
                            <Route exact path='/kcompositions'
                                render={ (props) =>
                                <KCompositions
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}

                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path={'/kcomposition/:id'}
                                render={ (props) =>
                                <KComposition
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}
                    
                    <Route exact path='/about'
                        render={ (props) =>
                        <About
                            {...props}/>
                        }
                    />

                    {/* {
                        this.props.app.web3 ?  */}
                            <Route exact path='/mutations'
                                render={ (props) =>
                                <Mutations
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}

                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path={'/variant/:id'}
                                render={ (props) =>
                                <Variant
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}
                    
                    {/* {
                        this.props.app.web3 ? */}
                            <Route exact path='/oneoffs'
                                render={ (props) =>
                                <OneOffs
                                    {...props}/>
                                }
                            />
                        {/* : null
                    } */}
                </Router>
            </React.Fragment>
        )
    }
}

App.contextTypes = {
    router: PropTypes.object,
    store: PropTypes.object
}
  
const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    app: state.app
})
    
const mapDispatchToProps = dispatch => bindActionCreators({
    initApp,
    // initWeb3
}, dispatch)
    
    
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(App))
