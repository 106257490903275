import { ethers } from "ethers"
import axios from 'axios'

import {
    METRO_CONTRACT_ADDRESS,
    // METRO_ALLOCATION_CONTRACT_ADDRESS
} from '../gener8tiveConstants'

import MetroSeriesERC721 from '../contracts/metro/MetroSeriesERC721.json'
// import MetroNycAllocations from '../contracts/metro/MetroNycAllocations.json'

import { REACT_GA_ENABLED } from '../gener8tiveConstants'

import ReactGA from 'react-ga'
import cookie from 'react-cookies'

export const GOT_MINT_STATUS = 'metro/GOT_MINT_STATUS'
export const WALLET_CONNECTED = 'metro/WALLET_CONNECTED'
export const METRO_TOKEN_MINTED_EVENT = 'metro/METRO_TOKEN_MINTED_EVENT'
export const MINT_SUCCESS = 'metro/MINT_SUCCESS'
export const MINT_FAILED = 'metro/MINT_FAILED'
export const CLEAR_UI_MESSAGE = 'metro/CLEAR_UI_MESSAGE'
export const SHOW_UI_FEEDBACK_ERROR = 'metro/SHOW_UI_FEEDBACK_ERROR'
export const RETRIEVED_MERKLE_PROOF = 'metro/RETRIEVED_MERKLE_PROOF'
export const RETRIEVED_PREMINT_ALLOCATION = 'metro/RETRIEVED_PREMINT_ALLOCATION'
export const TOKEN_BALANCE_RETRIEVED = 'metro/TOKEN_BALANCE_RETRIEVED'
export const NONCE_GENERATED = 'metro/NONCE_GENERATED'
export const SIGNING_STARTED = 'metro/SIGNING_STARTED'
export const SIGNING_COMPLETED = 'metro/SIGNING_COMPLETED'
export const GOT_COOKIE_EXPIRY = 'metro/GOT_COOKIE_EXPIRY'

const initialState = {
    address: null,
    walletConnected: false,
    // contract: null,
    premintAllocation: -1,
    tx: null,
    message: null,
    showUiFeedback: false,
    uiFeedbackMsg: null,
    messageType: null,
    merkleProof: null,
    tokenBalance: -1,
    nonce: 0,
    awaitingSignResponse: false,
    cookieExpiry: 0
}

export const connectWallet = () => {
    return async (dispatch, getState) => {
        // console.log('connectWallet')
        
        // const state = getState()

        if(!window.ethereum) {
            dispatch({
                type: SHOW_UI_FEEDBACK_ERROR,
                message: 'A Web3 Compatible browser is required.'
            })
            
            if(REACT_GA_ENABLED) {
                ReactGA.event({
                    category: 'Web3',
                    action: 'NoWeb3Compatibility'
                })
            }
            return;
        }

        let web3Provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
        let contract = new ethers.Contract(METRO_CONTRACT_ADDRESS, MetroSeriesERC721.abi, web3Provider)

        // contract.on('TokenMinted', (tokenIndex, seriesId, minter, maxSupply, mintPrice, isPresale) => {
        //     console.log(`TokenMinted event - tokenIndex: ${tokenIndex}, minter: ${minter}, saleStatus: ${isPresale}`);
        //     dispatch({
        //         type: METRO_TOKEN_MINTED_EVENT,
        //         tokenIndex: tokenIndex,
        //         seriesId: seriesId,
        //         minter: minter,
        //         maxSupply: maxSupply,
        //         mintPrice: mintPrice,
        //         isPresale: isPresale
        //     })
        // });

        await web3Provider.send("eth_requestAccounts", [])
        let signer = await web3Provider.getSigner()
        let address = await signer.getAddress()
        console.log('address', address)

        if(REACT_GA_ENABLED) {
            ReactGA.event({
                category: 'Web3',
                action: 'Wallet-Connected'
            })
        }

        dispatch({
            type: WALLET_CONNECTED,
            walletConnected: true,
            address: address,
            // contract: contract
        })

        let nonceResponse = await axios.post(`https://api.gener8tive.io/metro/cityviewer/nonce`, { address: address });
        if(nonceResponse.data.statusCode !== 200) {
            console.log("Error generating nonce")

            dispatch({
                type: SHOW_UI_FEEDBACK_ERROR,
                message: 'Error retrieving nonce - please try again later'
            })

            if(REACT_GA_ENABLED) {
                ReactGA.event({
                    category: 'API',
                    action: 'Nonce-Error'
                })
            }
        }
        else {
            dispatch({
                type: NONCE_GENERATED,
                nonce: nonceResponse.data.body.nonce
            })
        }
        // console.log('nonceResponse', nonceResponse.data.body)

        let balance = await contract.balanceOf(address);
        // console.log("tokenBalance:", balance)

        dispatch({
            type: TOKEN_BALANCE_RETRIEVED,
            balance: balance
        })

        let expiry = cookie.load("CityViewer-Access-Expires")
        const d = new Date();
        const epochTime = Math.floor(d.getTime() / 1000);
        if(epochTime > expiry) {
            expiry = 0
        }
        
        if(expiry) {
            dispatch({
                type: GOT_COOKIE_EXPIRY,
                expiry: expiry
            })
        }

        // if(state.metro.seriesData.privateMintActive) {
        //     let allowlistResponse = await axios.post(`https://api.gener8tive.io/metro/allowlist`, { address: address });
        //     // console.log('allowlistResponse', allowlistResponse)
        //     let merkleProof = allowlistResponse.data.proof
        //     // console.log('merkleProof', merkleProof)

        //     dispatch({
        //         type: RETRIEVED_MERKLE_PROOF,
        //         merkleProof: merkleProof,
        //         merkleList: allowlistResponse.data.list
        //     })

        //     let allocationContract = await new ethers.Contract(
        //         METRO_ALLOCATION_CONTRACT_ADDRESS,
        //         MetroNycAllocations.abi,
        //         web3Provider
        //     )
        //     let allocations = await allocationContract.getRemainingAllocation(address, merkleProof, "");
        //     // console.log('allocations', allocations.toNumber())

        //     dispatch({
        //         type: RETRIEVED_PREMINT_ALLOCATION,
        //         premintAllocation: allocations.toNumber()
        //     })
        // }
    }
}

export const signAndVerify = () => {
    return async (dispatch, getState) => {
        const state = getState()

        const web3Provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
        const signer = await web3Provider.getSigner()
        const msg = `Metro City Viewer auth with nonce ${state.metro.nonce}`
        let signedMsg = await signer.signMessage(msg)
        // console.log("signedMsg", signedMsg)

        dispatch({
            type: SIGNING_STARTED
        })

        let signResponse = await axios.post(`https://api.gener8tive.io/metro/cityviewer/validatemessage`,
            {
                address: state.metro.address,
                signedMsg: signedMsg
            }
        );

        if(signResponse.data.statusCode !== 200) {
            console.log("Errror validating msg nonce", signResponse) //TODO user feedback

            dispatch({
                type: SHOW_UI_FEEDBACK_ERROR,
                message: 'Error validating signed message'
            })

            if(REACT_GA_ENABLED) {
                ReactGA.event({
                    category: 'API',
                    action: 'Sign-Error'
                })
            }

            dispatch({
                type: SIGNING_COMPLETED
            })

            return
        }

        dispatch({
            type: SIGNING_COMPLETED
        })

        cookie.save("CloudFront-Policy", signResponse.data['CloudFront-Policy'], {domain: ".gener8tive.io"})
        cookie.save("CloudFront-Key-Pair-Id", signResponse.data['CloudFront-Key-Pair-Id'], {domain: ".gener8tive.io"})
        cookie.save("CloudFront-Signature", signResponse.data['CloudFront-Signature'], {domain: ".gener8tive.io"})
        cookie.save("CityViewer-Access-Expires", signResponse.data.expires, {domain: ".gener8tive.io"})

        cookie.save("CloudFront-Policy", signResponse.data['CloudFront-Policy'])
        cookie.save("CloudFront-Key-Pair-Id", signResponse.data['CloudFront-Key-Pair-Id'])
        cookie.save("CloudFront-Signature", signResponse.data['CloudFront-Signature'])
        cookie.save("CityViewer-Access-Expires", signResponse.data.expires)

        // window.open('http://metrocityviewer.gener8tive.io/index.html');
        // console.log("signResponse", signResponse.data)

        dispatch({
            type: GOT_COOKIE_EXPIRY,
            expiry: signResponse.data.expires
        })
    }
}

export const mintPremint = () => {
    return async (dispatch, getState) => {
        // console.log('mintPremint')

        const state = getState()
        // console.log('state', state)

        let mintPriceBN = ethers.BigNumber.from(state.metro.seriesData.privateMintPrice)
        // let price = mintPriceBN - (mintPriceBN * state.metro.seriesData.premintPercentage / 100)
        // console.log('price', mintPriceBN.toString())

        try {
            // let allocationContract = await new ethers.Contract(METRO_ALLOCATION_CONTRACT_ADDRESS, MetroNycAllocations.abi, web3Provider)
            // let allocations = await allocationContract.getRemainingAllocation(state.metro.address, state.metro.merkleProof);
            // console.log('allocations', allocations.toNumber())

            // console.log('state.metro.merkleProof', state.metro.merkleProof);

            let web3Provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
            let signer = await web3Provider.getSigner()

            const contract = state.metro.contract.connect(signer);
            let tx = await contract.privateMint(
                1,
                state.metro.merkleProof,
                "",
                { from: state.metro.address, value: mintPriceBN }
            )
            console.log('mintPremint tx:', tx)

            dispatch({
                type: MINT_SUCCESS,
                tx: tx
            })

            if(REACT_GA_ENABLED) {
                ReactGA.event({
                    category: 'Web3',
                    action: 'Premint-Success'
                })
            }
        }
        catch(err) {
            console.log(err)

            if(err.error && err.error.data) {
                console.log("err.error.data.message", err.error.data.message)

                dispatch({
                    type: MINT_FAILED,
                    message: err.error.data.message
                })

                if(REACT_GA_ENABLED) {
                    ReactGA.event({
                        category: 'Web3',
                        action: `Premint-Failed-Revert-${err.error.data.code}`
                    })
                }
            }
            else {
                dispatch({
                    type: MINT_FAILED,
                    message: err.message ? err.message : "Unable to mint at this time"
                })

                if(REACT_GA_ENABLED) {
                    ReactGA.event({
                        category: 'Web3',
                        action: 'Premint-Failed-Unknown'
                    })
                }
            }
        }
    }
}

export const mintPublic = () => {
    return async (dispatch, getState) => {
        // console.log('mintPublic')

        const state = getState()

        let mintPriceBN = ethers.BigNumber.from(state.metro.seriesData.publicMintPrice)

        try {
            let web3Provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
            let signer = await web3Provider.getSigner()
            const contract = state.metro.contract.connect(signer);
            let tx = await contract.publicMint(1, {value: mintPriceBN});

            dispatch({
                type: MINT_SUCCESS,
                tx: tx
            })

            if(REACT_GA_ENABLED) {
                ReactGA.event({
                    category: 'Web3',
                    action: 'PublicMint-Success'
                })
            }
        }
        catch(err) {
            // console.log("mintPublic err")
            if(err.error && err.error.data) {
                console.log("mintPublic err (1)")
                
                // console.log("err.error.data.message", err.error.data.message)
                // console.log("err.error.data.code", err.error.data.code)

                dispatch({
                    type: MINT_FAILED,
                    message: err.error.data.message
                })

                if(REACT_GA_ENABLED) {
                    ReactGA.event({
                        category: 'Web3',
                        action: `PublicMint-Failed-Revert-${err.error.data.code}`
                    })
                }
            }
            else {
                console.log("mintPublic err (2)")
                
                console.log("err.message:", err.message + "|")
                console.log("err.code:", err.code)

                dispatch({
                    type: MINT_FAILED,
                    message: err.message ? err.message : "Unable to mint at this time"
                })

                if(REACT_GA_ENABLED) {
                    if(err.message.includes("denied")) {
                        ReactGA.event({
                            category: 'Web3',
                            action: 'PublicMint-Failed-Tx-Denied'
                        })
                    }
                    else {
                        ReactGA.event({
                            category: 'Web3',
                            action: err.code ? `PublicMint-Failed-${err.code}` : 'PublicMint-Failed-Unknown'
                        })
                    }
                }
            }
        }
    }
}

export const clearUiFeedback = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: CLEAR_UI_MESSAGE
        })
    }
}

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
        
        case GOT_MINT_STATUS:
            let tmpBody = action.data.body
            // tmpBody.paused = true;

            return {
                ...state,
                seriesData: tmpBody
            }
        
        case WALLET_CONNECTED:
            // state.tokenMetadata[action.tokenId] = action.ipfsMetadata
            return {
                ...state,
                walletConnected: action.walletConnected,
                address: action.address,
                // contract: action.contract,
                signer: action.signer
            }

        case MINT_SUCCESS:
            console.log(action.tx)
            let premintAllocation = state.premintAllocation - 1
            return {
                ...state,
                tx: action.tx,
                premintAllocation: premintAllocation,
                showUiFeedback: true,
                uiFeedbackMsg: 'Transaction submitted. Please allow some time for processing on the Ethereum Blockchain.',
                messageType: 'success'
            }
        
        case MINT_FAILED:
            // TODO user feedback
            return{
                ...state,
                showUiFeedback: true,
                uiFeedbackMsg: action.message,
                messageType: 'error'
            }

        case CLEAR_UI_MESSAGE:
            return {
                ...state,
                showUiFeedback: false
            }

        case METRO_TOKEN_MINTED_EVENT:
            // console.log('METRO_TOKEN_MINTED_EVENT', action)
            let tmpSeriesData = state.seriesData
            tmpSeriesData.numMinted ++
            return {
                ...state,
                seriesData: tmpSeriesData
            }
        
        case RETRIEVED_MERKLE_PROOF:
            return {
                ...state,
                merkleProof: action.merkleProof
            }

        case RETRIEVED_PREMINT_ALLOCATION:
            return {
                ...state,
                premintAllocation: action.premintAllocation
            }

        case NONCE_GENERATED:
            return {
                ...state,
                nonce: action.nonce
            }
        
        case TOKEN_BALANCE_RETRIEVED:
            return {
                ...state,
                tokenBalance: action.balance
            }

        case GOT_COOKIE_EXPIRY:
            return {
                ...state,
                cookieExpiry: action.expiry
            }

        case SIGNING_STARTED:
                return {
                    ...state,
                    awaitingSignResponse: true
                }
        
        case SIGNING_COMPLETED:
            return {
                ...state,
                awaitingSignResponse: false
            }
        
        case SHOW_UI_FEEDBACK_ERROR:
            return {
                ...state,
                showUiFeedback: true,
                uiFeedbackMsg: action.message,
                messageType: 'error'
            }

        default:
            return state
    }
}

export default moduleActions